<template>
  <div>
    <v-tooltip bottom color="#311B92">
      <template v-slot:activator="{ on, attrs }">
        <div
          class="block-icon mr-2"
          v-on="on"
          v-bind="attrs"
          @click.stop="FacuresToConfirme"
        >
          <!-- <font-awesome-icon icon="check-double" class="file-medical" /> -->
          <IconBadgeCheck></IconBadgeCheck>
        </div>
      </template>
      <span>Valider des factures</span>
    </v-tooltip>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  components: {
    IconBadgeCheck: () => import('@/assets/img/IconBadgeCheck.vue')
  },
  props: { dataToUse: { required: true } },
  methods: {
    ...mapActions(['confirmedFactureFraisDynamicMulti']),
    async FacuresToConfirme() {
      let ids = [];
      let htmlMessage =
        "<div  style='margin: auto;width: 50%;height: 250px;overflow-x: auto;' class='ModalScrol'><div> ";

      for (let i = 0; i < this.dataToUse.length; i++) {
        for (let j = 0; j < this.dataToUse[i].projects.length; j++) {
          if (
            this.dataToUse[i].projects[j] &&
            this.dataToUse[i].projects[j].check == true &&
            this.dataToUse[i].projects[j].id &&
            this.dataToUse[i].projects[j].etat != 'Annulée' &&
            this.dataToUse[i].projects[j].modifiable == true &&
            this.dataToUse[i].projects[j].reglements.length == 0
          ) {
            ids.push(this.dataToUse[i].projects[j]);
            htmlMessage =
              htmlMessage +
              '<li>' +
              this.dataToUse[i].projects[j].num +
              '</li>';
          }
        }
      }
      htmlMessage = htmlMessage + '</div></div>';

      this.$swal
        .fire({
          title: 'Êtes-vous sur de confirmer ces factures ',
          type: 'warning',
          icon: 'warning',
          locale: 'fr',
          allowOutsideClick: () => !this.$swal.isLoading(),
          stopKeydownPropagation: false,
          confirmButtonText: 'Oui',
          cancelButtonText: 'Non',
          showCancelButton: true,
          confirmButtonClass: 'btn btn-success',
          cancelButtonClass: 'btn btn-danger',
          cancelButtonColor: '#d33',
          confirButtonColor: '#218838',
          html: htmlMessage,
          customClass: {
            actions: 'my-actions',
            cancelButton: 'order-2 ',
            confirmButton: 'order-1'
          },
          preConfirm: () => {
            this.$swal.showLoading();
          }
        })
        .then(async result => {
          if (result.isConfirmed) {
            const response = await this.confirmedFactureFraisDynamicMulti(ids);
          }
        });
    }
  }
};
</script>

<style></style>
